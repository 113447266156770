<template>
  <div class="invite">
    <div class="content">
      <div class="cover">
        <a-avatar shape="square" :size="130" :src="group.coverImg">
          {{ group.groupName }}
        </a-avatar>
      </div>
      <h3>{{ group.groupName }}</h3>
      <p class="master">
        {{ $t('bbs.circle_owner') }}：<OpenData type="userName" :openid="group.masterUser" />
        <!-- 圈主： -->
      </p>
      <div class="box">
        <OpenData type="userName" :openid="group.inviteUser" />{{ $t('bbs.invite_you_join_circle') }}
        <!-- 邀请你加入学习圈 -->
      </div>
      <a-button class="btn" type="primary" v-if="group.state < 8" @click="agree"
        >{{ $t('bbs.join_now') }}</a-button
      >
      <!-- 立即加入 -->
      <a-button class="btn" type="primary" v-else @click="jump"
        >{{ $t('bbs.enter_circle') }}</a-button
      >
      <!-- 进入圈子 -->
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, createVNode, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";

import { groupInviteJoinInfo, agreeJoinGroup } from "@/api/bbs";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import OpenData from "@/components/OpenData.vue";

export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      groupId: Number(route.query.groupId || 0),
      group: {},
    });

    groupInviteJoinInfo({
      id: state.groupId,
    }).then((res) => {
      if (res.ret === 0) {
        state.group = res.data;
      }
    });

    const jump = () => {
      window.location.href = `/bbs/group?groupId=${state.groupId}`;
    };

    const agree = () => {
      Modal.confirm({
        title: () => $t('bbs.sure_agree_join'),
        // 确定同意加入吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          agreeJoinGroup({
            id: state.groupId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              jump();
            }
          });
        },
      });
    };

    return {
      ...toRefs(state),
      jump,
      agree,
    };
  },
};
</script>

<style lang="less" scoped>
.invite {
  padding: 34px 0 150px;
  .content {
    .mixinWrap(934px);
    height: 550px;
    background-color: #fff;
    padding-top: 64px;
    .mixinFlex(flex-start; center; column);
    .cover {
      margin-bottom: 22px;
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: #202020;
      margin-bottom: 14px;
    }
    .master {
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 42px;
    }
    .box {
      display: inline-block;
      padding: 0 50px;
      min-width: 348px;
      height: 40px;
      border-radius: 4px;
      line-height: 40px;
      background-color: #f2f2f2;
      text-align: center;
      margin-bottom: 80px;
    }
    .btn {
      width: 280px;
      height: 40px;
      font-size: 16px;
    }
  }
}
</style>
